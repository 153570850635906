@layer components {
    .textWithLabel {
        @apply grid grid-cols-1 w-full gap-3 mt-4;

        label {
            @apply text-vacayou-defaultTextGray font-bold font-lato;
        }
        input {
            /* basic styling */
            // @apply font-lato ring-vacayou-lightGrey ring-2  text-base p-3 text-vacayou-defaultTextGray;
            background: #f2f2f2;
            border: 2px solid #979797;
            border-radius: 3px;
            padding: 16px 18px;
            transition: 0.3s;
            &:focus {
                outline: none;
                border-color: #234c79;
            }
        }
    }

    .tooltip {
        height: 115px;
        width: 240px;
        position: absolute;
        background-color: transparent;
        @apply mt-5;
        > div {
            width: 50px;
            height: 25px;
            position: absolute;
            left: 0;
            transform: translateY(-20px);
            overflow: hidden;

            &::after {
                content: "";
                position: absolute;
                width: 20px;
                height: 20px;
                transform: translateX(-50%) translateY(10px) rotate(45deg);
                left: 50%;
                background: #f2f2f2;
            }
        }
        > ul {
            @apply absolute py-3 px-3 text-base w-full gap-y-2 grid divide-y-2 divide-vacayou-borderGrey;
            background: #f2f2f2;
            > li {
                @apply px-3;
                cursor: pointer;
                border: none;
                padding-top: 5px;
            }
        }
    }

    .socialButton {
        @apply border-none text-left p-3 text-lg font-semibold w-full my-2;
        box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 20%), 0 1px 5px 0 rgb(0 0 0 / 12%);
    }

    .googleGradient {
        background: conic-gradient(
                from -45deg,
                #ea4335 110deg,
                #4285f4 90deg 180deg,
                #34a853 180deg 270deg,
                #fbbc05 270deg
            )
            73% 55%/150% 150% no-repeat;
        background-clip: border-box;
        -webkit-background-clip: text;
        background-clip: text;
        color: transparent;
        -webkit-text-fill-color: transparent;
    }

    .googleButton {
        @apply socialButton font-source;
        @apply text-vacayou-defaultTextGray;
        background-color: white;
        > i {
            @apply googleGradient pr-4;
        }
    }

    .facebookButton {
        @apply socialButton font-source;
        @apply text-white;
        background-color: #4c5992;
        > i {
            @apply pr-4;
        }
    }
}

@layer utilities {
    @variants responsive {
        .left-unset {
            left: unset;
        }
    }
}

.popupWrapper {
    left: 50%;
    transform: translate(-50%, 0);
    @apply md:top-16 md:right-5 md:left-unset md:transform-none w-3/4 md:w-auto;

    @apply bottom-1 fixed z-20;
    > div {
        @apply mt-3;
    }
}

.popup {
    @apply text-white  cursor-pointer sm:w-96 w-full py-2 rounded-sm drop-shadow-sm;
}

@keyframes fadeOut {
    0% {
        opacity: 1;
    }
    90% {
        opacity: 0;
    }
    100% {
        opacity: 0;
    }
}

.fadeOut {
    animation-name: fadeOut;
    animation-duration: 300ms;
    animation-fill-mode: forwards;
}

.primaryButton {
    @apply px-5 py-3 font-bold bg-vacayou-primaryDark text-white uppercase text-center;
}

/* Following lines are to allow tailwind to resolve these classes, as they are injected dynamically */
.popupError {
    @apply bg-red-400;
}
.popupWarning {
    @apply bg-yellow-400;
}
.popupSuccess {
    @apply bg-green-400;
}

/* Modal */

.modalWrapper {
    @apply w-screen h-screen fixed top-0 left-0 bg-opacity-75 bg-black blur-3xl z-20;
    > div {
        @apply flex h-screen;
        > div {
            @apply m-auto bg-white px-7 py-5 relative;
            box-shadow: 0px 0px 5px black;
            border-bottom: 5px solid #14588d;
            > i {
                @apply absolute right-2 top-2 cursor-pointer;
            }
        }
    }
}
