.textWithLabel{
  margin-top:1rem;
  display:grid;
  width:100%;
  grid-template-columns:repeat(1, minmax(0, 1fr));
  gap:0.75rem
}

.textWithLabel label{
  font-family:Lato, sans-serif;
  font-weight:700;
  --tw-text-opacity:1;
  color:rgba(87, 87, 87, var(--tw-text-opacity))
}

.textWithLabel input{
  background:#f2f2f2;
  border:2px solid #979797;
  border-radius:3px;
  padding:16px 18px;
  transition:.3s
}

.textWithLabel input:focus{
  outline:none;
  border-color:#234c79
}

.tooltip{
  height:115px;
  width:240px;
  position:absolute;
  background-color:transparent;
  margin-top:1.25rem
}

.tooltip>div{
  width:50px;
  height:25px;
  position:absolute;
  left:0;
  transform:translateY(-20px);
  overflow:hidden
}

.tooltip>div::after{
  content:"";
  position:absolute;
  width:20px;
  height:20px;
  transform:translateX(-50%) translateY(10px) rotate(45deg);
  left:50%;
  background:#f2f2f2
}

.tooltip>ul{
  position:absolute;
  display:grid;
  width:100%;
  row-gap:0.5rem
}

.tooltip>ul > :not([hidden]) ~ :not([hidden]){
  --tw-divide-y-reverse:0;
  border-top-width:calc(2px * calc(1 - var(--tw-divide-y-reverse)));
  border-bottom-width:calc(2px * var(--tw-divide-y-reverse));
  --tw-divide-opacity:1;
  border-color:rgba(221, 221, 221, var(--tw-divide-opacity))
}

.tooltip>ul{
  padding-left:0.75rem;
  padding-right:0.75rem;
  padding-top:0.75rem;
  padding-bottom:0.75rem;
  font-size:1rem;
  line-height:1.5rem;
  background:#f2f2f2
}

.tooltip>ul>li{
  padding-left:0.75rem;
  padding-right:0.75rem;
  cursor:pointer;
  border:none;
  padding-top:5px
}

.googleButton{
  font-family:"Source Sans Pro", sans-serif;
  margin-top:0.5rem;
  margin-bottom:0.5rem;
  width:100%;
  border-style:none;
  padding:0.75rem;
  text-align:left;
  font-size:1.125rem;
  line-height:1.75rem;
  font-weight:600;
  box-shadow:0 2px 2px 0 rgba(0,0,0,.14),0 3px 1px -2px rgba(0,0,0,.2),0 1px 5px 0 rgba(0,0,0,.12);
  --tw-text-opacity:1;
  color:rgba(87, 87, 87, var(--tw-text-opacity));
  background-color:#fff
}

.googleButton>i{
  padding-right:1rem;
  background:conic-gradient(from -45deg, #ea4335 110deg, #4285f4 90deg 180deg, #34a853 180deg 270deg, #fbbc05 270deg) 73% 55%/150% 150% no-repeat;
  background-clip:border-box;
  -webkit-background-clip:text;
  background-clip:text;
  color:transparent;
  -webkit-text-fill-color:transparent
}

.facebookButton{
  font-family:"Source Sans Pro", sans-serif;
  margin-top:0.5rem;
  margin-bottom:0.5rem;
  width:100%;
  border-style:none;
  padding:0.75rem;
  text-align:left;
  font-size:1.125rem;
  line-height:1.75rem;
  font-weight:600;
  box-shadow:0 2px 2px 0 rgba(0,0,0,.14),0 3px 1px -2px rgba(0,0,0,.2),0 1px 5px 0 rgba(0,0,0,.12);
  --tw-text-opacity:1;
  color:rgba(255, 255, 255, var(--tw-text-opacity));
  background-color:#4c5992
}

.facebookButton>i{
  padding-right:1rem
}

.popupWrapper{
  left:50%;
  transform:translate(-50%, 0);
  width:75%
}

@media (min-width: 768px){
  .popupWrapper{
    top:4rem;
    right:1.25rem;
    width:auto;
    transform:none;
    left:unset
  }
}

.popupWrapper{
  position:fixed;
  bottom:0.25rem;
  z-index:20
}

.popupWrapper>div{
  margin-top:0.75rem
}

.popup{
  width:100%;
  cursor:pointer;
  border-radius:0.125rem;
  padding-top:0.5rem;
  padding-bottom:0.5rem;
  --tw-text-opacity:1;
  color:rgba(255, 255, 255, var(--tw-text-opacity));
  --tw-drop-shadow:drop-shadow(0 1px 1px rgba(0,0,0,0.05))
}

@media (min-width: 640px){
  .popup{
    width:24rem
  }
}

@-webkit-keyframes fadeOut{
  0%{
    opacity:1
  }

  90%{
    opacity:0
  }

  100%{
    opacity:0
  }
}

@keyframes fadeOut{
  0%{
    opacity:1
  }

  90%{
    opacity:0
  }

  100%{
    opacity:0
  }
}

.fadeOut{
  -webkit-animation-name:fadeOut;
          animation-name:fadeOut;
  -webkit-animation-duration:300ms;
          animation-duration:300ms;
  -webkit-animation-fill-mode:forwards;
          animation-fill-mode:forwards
}

.primaryButton{
  --tw-bg-opacity:1;
  background-color:rgba(35, 76, 121, var(--tw-bg-opacity));
  padding-left:1.25rem;
  padding-right:1.25rem;
  padding-top:0.75rem;
  padding-bottom:0.75rem;
  text-align:center;
  font-weight:700;
  text-transform:uppercase;
  --tw-text-opacity:1;
  color:rgba(255, 255, 255, var(--tw-text-opacity))
}

.popupError{
  --tw-bg-opacity:1;
  background-color:rgba(248, 113, 113, var(--tw-bg-opacity))
}

.popupWarning{
  --tw-bg-opacity:1;
  background-color:rgba(251, 191, 36, var(--tw-bg-opacity))
}

.popupSuccess{
  --tw-bg-opacity:1;
  background-color:rgba(52, 211, 153, var(--tw-bg-opacity))
}

.modalWrapper{
  position:fixed;
  top:0px;
  left:0px;
  z-index:20;
  height:100vh;
  width:100vw;
  --tw-bg-opacity:1;
  background-color:rgba(0, 0, 0, var(--tw-bg-opacity));
  --tw-bg-opacity:0.75;
  --tw-blur:blur(64px)
}

.modalWrapper>div{
  display:flex;
  height:100vh
}

.modalWrapper>div>div{
  position:relative;
  margin:auto;
  --tw-bg-opacity:1;
  background-color:rgba(255, 255, 255, var(--tw-bg-opacity));
  padding-left:1.75rem;
  padding-right:1.75rem;
  padding-top:1.25rem;
  padding-bottom:1.25rem;
  box-shadow:0px 0px 5px #000;
  border-bottom:5px solid #14588d
}

.modalWrapper>div>div>i{
  position:absolute;
  top:0.5rem;
  right:0.5rem;
  cursor:pointer
}

@media (min-width: 640px){
}

@media (min-width: 768px){
}

@media (min-width: 1024px){
}

@media (min-width: 1280px){
}

@media (min-width: 1536px){
}