/*==============================================================================
// File:        _variables.scss
// Synopsis:    Main variables & reusable items - SASS
//============================================================================*/

/*------------------------------------------------------------------------------
// Global Variables
//----------------------------------------------------------------------------*/

/// Brand Colors
$primary: #214d79;
$primary-dark: #234c79;
$vacayou-blue: #1f73b4;
$vacayou-blue-dark: #3166a0;
$secondary: $vacayou-blue;
$secondary-dark: $vacayou-blue-dark;
$vacayou-teal: #288f91;
$vacayou-teal-dark: #237172;
$default: #575757;
$default-dark: #444444;
$alternate: #9d9d9d;
$alternate-dark: #302e2c;

// Support Colors
$vacayou-red: #e05543;
$vacayou-red-dark: #c3402f;
$vacayou-red-hover: $vacayou-red-dark;
$vacayou-alternate: #6bbfc5;
$vacayou-alternate-dark: #50a8ae;
$vacayou-alternate-hover: $vacayou-alternate-dark;
$vacayou-light: #daeff4;
$vacayou-lighter: #ecf7f9;
$vacayou-lightgrey: #f0f0f0;
$vacayou-lightgrey-lighter: #f4f0eb;

// COLOR RULES
//
// * Headings, featured copy or icons in dark grey, light grey, blue or teal
// * Color blocks with headings/copy in reverse white out
// * Copy in black, dark grey, light grey or white (if reversed out within a color block)
// * Orange should be used for critical call-outs of copy, action buttons, icons or visuals to highlight hallmarks

// Fonts
$heading-font: "Lato", Helvetica, Arial, sans-serif;
$body-font: "Source Sans Pro", Helvetica, Arial, sans-serif;
$script-font: "Brittany Signature", cursive;

// Font Weights
$regular: 400;
$bold: 700;
$thin: 100;
