@tailwind base;
@tailwind components;
@tailwind utilities;

@import "../node_modules/@fortawesome/fontawesome-free/css/all.css";
@import "../node_modules/react-image-crop/dist/ReactCrop.css";

@import "./variables";
@import "./fonts";

@responsive {
    .text-shadow {
        text-shadow: 0px 2px 3px rgb(0 0 0 / 94%);
    }
    .text-shadow-none {
        text-shadow: none;
    }
}

html,
body {
    padding: 0;
    margin: 0;
    height: 100%;
    overflow-x: hidden;
    @apply font-lato;
}

body {
    position: relative;
    width: 100%;
}

main {
    display: flex !important;
    min-height: 100vh;
    min-width: 320px;
    flex-direction: column;
    justify-content: flex-start;
    overflow-x: hidden;
}

a {
    color: inherit;
    text-decoration: none;
}

p {
    & a {
        text-decoration: underline;
        color: #234c79;
        font-weight: bold;
    }
}

* {
    box-sizing: border-box;
    @apply font-lato;
}

nav {
    height: 60px;
    @apply w-full bg-white;
}

// TODO move these classes to carousel styles in a style cleanup ticket, but it's fine here for now
// Definitely want to get rid of the !important flags
.swiper-container {
    padding-bottom: 4px !important; // card box shadow will not display without this
    padding-top: 4px !important; // card box shadow will not display without this
}

.swiper-button-prev {
    height: 32px !important;
    width: 40px !important;
    background-image: url("../public/images/carousel/carousel-arrow-left.svg") !important;
    object-fit: contain !important;
    &:hover {
        width: 32px !important;
        background-image: url("../public/images/carousel/carousel-arrow-left-hover.svg") !important;
    }
    &:after {
        content: "" !important;
    }
}

.swiper-button-next {
    height: 32px !important;
    width: 40px !important;
    background-image: url("../public/images/carousel/carousel-arrow-right.svg") !important;
    object-fit: contain !important;
    &:hover {
        width: 32px !important;
        background-image: url("../public/images/carousel/carousel-arrow-right-hover.svg") !important;
    }
    &:after {
        content: "" !important;
    }
}

.swiper-button-disabled {
    display: none !important;
}

.swiper-container {
    overflow: visible;
}
.swiper-pagination {
    position: absolute;
    bottom: -30px !important;
}

.swiper-pagination-bullet {
    width: 12px;
    height: 12px;
    &.swiper-pagination-bullet-active {
        background-color: #858266;
        opacity: 1;
    }
}

@media only screen and (max-width: 767px) {
    .contentWrapper {
        margin-top: 90px !important;
    }
}

// TODO clean up after launch
.navigation-button {
    margin-top: -14px !important;
    font-size: 15px !important;
    @media screen and (max-width: 1279px) {
        margin-top: -12px !important;
    }
    @media screen and (max-width: 991px) {
        margin-top: 0 !important;
        background: white !important;
        text-transform: none !important;
        color: #0e2b39 !important;
        width: 100% !important;
        text-align: start !important;
        padding-left: 12.8px !important;
        padding-right: 12.8px !important;
        padding-top: 6.4px !important;
        padding-bottom: 6.4px !important;
        justify-content: start !important;
        font-weight: 400 !important;
        &:hover {
            transform: none !important;
            background: #edf2f7 !important;
            color: #0e2b39 !important;
        }
    }
}

ul {
    list-style-type: initial;
    -webkit-margin-start: 1em !important;
    margin-inline-start: 1em !important;
    li {
        display: list-item;
        text-align: -webkit-match-parent;
        padding-bottom: 8px;
    }
}

// For datepicker hiding behind components
.react-datepicker-popper {
    z-index: 9999 !important;
}

.react-react-select-container {
    background-color: "white";
}
