.emailCaptureForm {
    .hubspotForm {
        width: 100%;
        form {
            display: flex;
            flex-direction: row;
            width: inherit;

            label {
                display: none;
            }

            input {
                padding: 10px;
                background: #fffef7;
                border-radius: 0 !important;
            }

            input[type="email"] {
                min-width: 156px;
                color: black;
                font-size: 12px;

                &::placeholder {
                    font-size: 12px;
                    color: black;
                    opacity: 1;
                }
            }
            input[type="submit"] {
                font-size: 12px;
                font-weight: 600;
                text-transform: uppercase;
                color: #858266;
            }
            input[type="submit"]:hover {
                cursor: pointer;
                background: #b3b2ab;
                color: #fffef7;
            }
        }
    }
}
