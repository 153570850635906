// HEADING FONTS – LATO THIN (100), LATO REGULAR (400), LATO BOLD (700)

/* lato-100 - latin */
@font-face {
    font-family: "Lato";
    font-style: normal;
    font-weight: 100;
    src: url("../public/fonts/lato-v19-latin-100.eot"); /* IE9 Compat Modes */
    src: local(""),
        url("../public/fonts/lato-v19-latin-100.eot?#iefix")
            format("embedded-opentype"),
        /* IE6-IE8 */ url("../public/fonts/lato-v19-latin-100.woff2")
            format("woff2"),
        /* Super Modern Browsers */
            url("../public/fonts/lato-v19-latin-100.woff") format("woff"),
        /* Modern Browsers */ url("../public/fonts/lato-v19-latin-100.ttf")
            format("truetype"),
        /* Safari, Android, iOS */
            url("../public/fonts/lato-v19-latin-100.svg#Lato") format("svg"); /* Legacy iOS */
}
/* lato-100italic - latin */
@font-face {
    font-family: "Lato";
    font-style: italic;
    font-weight: 100;
    src: url("../public/fonts/lato-v19-latin-100italic.eot"); /* IE9 Compat Modes */
    src: local(""),
        url("../public/fonts/lato-v19-latin-100italic.eot?#iefix")
            format("embedded-opentype"),
        /* IE6-IE8 */ url("../public/fonts/lato-v19-latin-100italic.woff2")
            format("woff2"),
        /* Super Modern Browsers */
            url("../public/fonts/lato-v19-latin-100italic.woff") format("woff"),
        /* Modern Browsers */
            url("../public/fonts/lato-v19-latin-100italic.ttf")
            format("truetype"),
        /* Safari, Android, iOS */
            url("../public/fonts/lato-v19-latin-100italic.svg#Lato")
            format("svg"); /* Legacy iOS */
}
/* lato-regular - latin */
@font-face {
    font-family: "Lato";
    font-style: normal;
    font-weight: 400;
    src: url("../public/fonts/lato-v19-latin-regular.eot"); /* IE9 Compat Modes */
    src: local(""),
        url("../public/fonts/lato-v19-latin-regular.eot?#iefix")
            format("embedded-opentype"),
        /* IE6-IE8 */ url("../public/fonts/lato-v19-latin-regular.woff2")
            format("woff2"),
        /* Super Modern Browsers */
            url("../public/fonts/lato-v19-latin-regular.woff") format("woff"),
        /* Modern Browsers */ url("../public/fonts/lato-v19-latin-regular.ttf")
            format("truetype"),
        /* Safari, Android, iOS */
            url("../public/fonts/lato-v19-latin-regular.svg#Lato") format("svg"); /* Legacy iOS */
}
/* lato-italic - latin */
@font-face {
    font-family: "Lato";
    font-style: italic;
    font-weight: 400;
    src: url("../public/fonts/lato-v19-latin-italic.eot"); /* IE9 Compat Modes */
    src: local(""),
        url("../public/fonts/lato-v19-latin-italic.eot?#iefix")
            format("embedded-opentype"),
        /* IE6-IE8 */ url("../public/fonts/lato-v19-latin-italic.woff2")
            format("woff2"),
        /* Super Modern Browsers */
            url("../public/fonts/lato-v19-latin-italic.woff") format("woff"),
        /* Modern Browsers */ url("../public/fonts/lato-v19-latin-italic.ttf")
            format("truetype"),
        /* Safari, Android, iOS */
            url("../public/fonts/lato-v19-latin-italic.svg#Lato") format("svg"); /* Legacy iOS */
}
/* lato-700 - latin */
@font-face {
    font-family: "Lato";
    font-style: normal;
    font-weight: 700;
    src: url("../public/fonts/lato-v19-latin-700.eot"); /* IE9 Compat Modes */
    src: local(""),
        url("../public/fonts/lato-v19-latin-700.eot?#iefix")
            format("embedded-opentype"),
        /* IE6-IE8 */ url("../public/fonts/lato-v19-latin-700.woff2")
            format("woff2"),
        /* Super Modern Browsers */
            url("../public/fonts/lato-v19-latin-700.woff") format("woff"),
        /* Modern Browsers */ url("../public/fonts/lato-v19-latin-700.ttf")
            format("truetype"),
        /* Safari, Android, iOS */
            url("../public/fonts/lato-v19-latin-700.svg#Lato") format("svg"); /* Legacy iOS */
}
/* lato-700italic - latin */
@font-face {
    font-family: "Lato";
    font-style: italic;
    font-weight: 700;
    src: url("../public/fonts/lato-v19-latin-700italic.eot"); /* IE9 Compat Modes */
    src: local(""),
        url("../public/fonts/lato-v19-latin-700italic.eot?#iefix")
            format("embedded-opentype"),
        /* IE6-IE8 */ url("../public/fonts/lato-v19-latin-700italic.woff2")
            format("woff2"),
        /* Super Modern Browsers */
            url("../public/fonts/lato-v19-latin-700italic.woff") format("woff"),
        /* Modern Browsers */
            url("../public/fonts/lato-v19-latin-700italic.ttf")
            format("truetype"),
        /* Safari, Android, iOS */
            url("../public/fonts/lato-v19-latin-700italic.svg#Lato")
            format("svg"); /* Legacy iOS */
}

// PARAGRAPH FONTS – SOURCE SANS PRO REGULAR (400), ITALIC, BOLD (700)

/* source-sans-pro-regular - latin */
@font-face {
    font-family: "Source Sans Pro";
    font-style: normal;
    font-weight: 400;
    src: url("../public/fonts/source-sans-pro-v14-latin-regular.eot"); /* IE9 Compat Modes */
    src: local(""),
        url("../public/fonts/source-sans-pro-v14-latin-regular.eot?#iefix")
            format("embedded-opentype"),
        /* IE6-IE8 */
            url("../public/fonts/source-sans-pro-v14-latin-regular.woff2")
            format("woff2"),
        /* Super Modern Browsers */
            url("../public/fonts/source-sans-pro-v14-latin-regular.woff")
            format("woff"),
        /* Modern Browsers */
            url("../public/fonts/source-sans-pro-v14-latin-regular.ttf")
            format("truetype"),
        /* Safari, Android, iOS */
            url("../public/fonts/source-sans-pro-v14-latin-regular.svg#SourceSansPro")
            format("svg"); /* Legacy iOS */
}
/* source-sans-pro-italic - latin */
@font-face {
    font-family: "Source Sans Pro";
    font-style: italic;
    font-weight: 400;
    src: url("../public/fonts/source-sans-pro-v14-latin-italic.eot"); /* IE9 Compat Modes */
    src: local(""),
        url("../public/fonts/source-sans-pro-v14-latin-italic.eot?#iefix")
            format("embedded-opentype"),
        /* IE6-IE8 */
            url("../public/fonts/source-sans-pro-v14-latin-italic.woff2")
            format("woff2"),
        /* Super Modern Browsers */
            url("../public/fonts/source-sans-pro-v14-latin-italic.woff")
            format("woff"),
        /* Modern Browsers */
            url("../public/fonts/source-sans-pro-v14-latin-italic.ttf")
            format("truetype"),
        /* Safari, Android, iOS */
            url("../public/fonts/source-sans-pro-v14-latin-italic.svg#SourceSansPro")
            format("svg"); /* Legacy iOS */
}
/* source-sans-pro-700 - latin */
@font-face {
    font-family: "Source Sans Pro";
    font-style: normal;
    font-weight: 700;
    src: url("../public/fonts/source-sans-pro-v14-latin-700.eot"); /* IE9 Compat Modes */
    src: local(""),
        url("../public/fonts/source-sans-pro-v14-latin-700.eot?#iefix")
            format("embedded-opentype"),
        /* IE6-IE8 */ url("../public/fonts/source-sans-pro-v14-latin-700.woff2")
            format("woff2"),
        /* Super Modern Browsers */
            url("../public/fonts/source-sans-pro-v14-latin-700.woff")
            format("woff"),
        /* Modern Browsers */
            url("../public/fonts/source-sans-pro-v14-latin-700.ttf")
            format("truetype"),
        /* Safari, Android, iOS */
            url("../public/fonts/source-sans-pro-v14-latin-700.svg#SourceSansPro")
            format("svg"); /* Legacy iOS */
}
/* source-sans-pro-700italic - latin */
@font-face {
    font-family: "Source Sans Pro";
    font-style: italic;
    font-weight: 700;
    src: url("../public/fonts/source-sans-pro-v14-latin-700italic.eot"); /* IE9 Compat Modes */
    src: local(""),
        url("../public/fonts/source-sans-pro-v14-latin-700italic.eot?#iefix")
            format("embedded-opentype"),
        /* IE6-IE8 */
            url("../public/fonts/source-sans-pro-v14-latin-700italic.woff2")
            format("woff2"),
        /* Super Modern Browsers */
            url("../public/fonts/source-sans-pro-v14-latin-700italic.woff")
            format("woff"),
        /* Modern Browsers */
            url("../public/fonts/source-sans-pro-v14-latin-700italic.ttf")
            format("truetype"),
        /* Safari, Android, iOS */
            url("../public/fonts/source-sans-pro-v14-latin-700italic.svg#SourceSansPro")
            format("svg"); /* Legacy iOS */
}

// SCRIPT FONT – BRITTANY SIGNATURE REGULAR (400)
@font-face {
    font-family: "Brittany Signature";
    font-style: normal;
    font-weight: 400;
    src: local(""), url("../public/fonts/BrittanySignature.woff") format("woff"); /* Modern Browsers */
}

// VAC Icons - Source: "http://fontello.com/"

@font-face {
    font-family: "fontello";
    src: url("../public/fonts/fontello.eot");
    src: url("../public/fonts/fontello.eot#iefix") format("embedded-opentype"),
        url("../public/fonts/fontello.woff") format("woff"),
        url("../public/fonts/fontello.ttf") format("truetype"),
        url("../public/fonts/fontello.svg#fontello") format("svg");
    font-weight: normal;
    font-display: swap;
    font-style: normal;
}

.fontello {
    /* Use important to prevent issues */
    font-family: "fontello" !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-variant: normal;
    text-transform: none;
    font-weight: normal;
    font-style: normal;
    line-height: 1;
    speak: none;
}

// Standard Icon Names

.icon-city-center:before {
    content: "\e80f";
}
.icon-guided-tour:before {
    content: "\e810";
}
.icon-adventure-and-adrenaline:before {
    content: "\e803";
}
.icon-anti-ageing:before {
    content: "\e804";
}
.icon-ayurveda:before {
    content: "\e805";
}
.icon-beach:before {
    content: "\e806";
}
.icon-boating:before {
    content: "\e807";
}
.icon-calender:before {
    content: "\e808";
}
.icon-camping:before {
    content: "\e809";
}
.icon-canoeing:before {
    content: "\e80a";
}
.icon-chevron-down:before {
    content: "\e80b";
}
.icon-climbing:before {
    content: "\e80c";
}
.icon-cooking-classes:before {
    content: "\e80d";
}
.icon-healthy-food:before {
    content: "\e812";
}
.icon-detox:before {
    content: "\e818";
}
.icon-fishing:before {
    content: "\e819";
}
.icon-fishing-2:before {
    content: "\e81a";
}
.icon-culinary:before {
    content: "\e81b";
}
.icon-crossfit:before {
    content: "\e81c";
}
.icon-corporate:before {
    content: "\e81d";
}
.icon-health:before {
    content: "\e81e";
}
.icon-hiking:before {
    content: "\e820";
}
.icon-holistic-education:before {
    content: "\e821";
}
.icon-horseback-riding:before {
    content: "\e822";
}
.icon-hotel:before {
    content: "\e823";
}
.icon-hydrotherapy:before {
    content: "\e824";
}
.icon-luxury-villa:before {
    content: "\e826";
}
.icon-luxury:before {
    content: "\e827";
}
.icon-medical-services:before {
    content: "\e829";
}
.icon-sustainable:before {
    content: "\e82a";
}
.icon-organic-food:before {
    content: "\e82c";
}
.icon-pilates:before {
    content: "\e82d";
}
.icon-skiing:before {
    content: "\e82e";
}
.icon-massage:before {
    content: "\e82f";
}
.icon-spa:before {
    content: "\e831";
}
.icon-rafting:before {
    content: "\e832";
}
.icon-running:before {
    content: "\e834";
}
.icon-sightseeing:before {
    content: "\e835";
}
.icon-medi-clinic:before {
    content: "\e836";
}
.icon-meditation:before {
    content: "\e837";
}
.icon-spiritual:before {
    content: "\e838";
}
.icon-surfing:before {
    content: "\e839";
}
.icon-upscale:before {
    content: "\e83a";
}
.icon-vegan:before {
    content: "\e83b";
}
.icon-vegetarian:before {
    content: "\e83c";
}
.icon-villa:before {
    content: "\e83d";
}
.icon-watersports:before {
    content: "\e83e";
}
.icon-winter:before {
    content: "\e840";
}
.icon-yoga:before {
    content: "\e841";
}
.icon-holistic-wellness:before {
    content: "\e970";
}
.icon-spa-treatments:before {
    content: "\e971";
}
.icon-group-females:before {
    content: "\e972";
}

// Custom Naming Conventions for Activity Icons

.icon-3-star-hotel:before,
.icon-3-star:before {
    content: "\e800";
}

.icon-4-star-hotel:before,
.icon-4-star:before {
    content: "\e801";
}

.icon-5-star-hotel:before,
.icon-5-star:before {
    content: "\e802";
}

.icon-cycling:before,
.icon-biking:before {
    content: "\e80e";
}

.icon-all-inclusive:before,
.icon-full-board:before {
    content: "\e811";
}

.icon-forrest-bathing:before,
.icon-forest-bathing:before {
    content: "\e813";
}

.icon-golf:before {
    content: "\e814";
}
.icon-half-board:before {
    content: "\e815";
}

.icon-great-for-couples:before,
.icon-romance:before,
.icon-couples:before {
    content: "\e816";
}

.icon-great-for-friends:before,
.icon-friends:before {
    content: "\e817";
}

.icon-fitness-programs:before,
.icon-fitness:before {
    content: "\e81f";
}

.icon-great-for-large-groups:before,
.icon-large-groups:before {
    content: "\e825";
}

.icon-remote-destination:before,
.icon-remote-location:before {
    content: "\e828";
}

.icon-scuba-diving:before,
.icon-scuba:before {
    content: "\e82b";
}

.icon-great-for-solo-travellers:before,
.icon-great-for-solo-travelers:before,
.icon-solo:before {
    content: "\e830";
}

.icon-specialty-spa:before,
.icon-special-spa:before {
    content: "\e833";
}

.icon-weight-loss-programs:before,
.icon-weight-management:before {
    content: "\e83f";
}

/* Countries */
.icon-algeria:before {
    content: "\e842";
}
.icon-angola:before {
    content: "\e843";
}
.icon-benin:before {
    content: "\e844";
}
.icon-botswana:before {
    content: "\e845";
}
.icon-burkina-faso:before {
    content: "\e846";
}
.icon-cameroon:before {
    content: "\e847";
}
.icon-burundi:before {
    content: "\e848";
}
.icon-cape-verde:before {
    content: "\e849";
}
.icon-central-african-republic:before {
    content: "\e84a";
}
.icon-chad:before {
    content: "\e84b";
}
.icon-comoros-islands:before {
    content: "\e84c";
}
.icon-democratic-republic-of-congo:before {
    content: "\e84d";
}
.icon-djibouti:before {
    content: "\e84e";
}
.icon-egypt:before {
    content: "\e84f";
}
.icon-equatorial-guinea:before {
    content: "\e850";
}
.icon-eritrea:before {
    content: "\e851";
}
.icon-eswatini:before {
    content: "\e852";
}
.icon-etheopia:before {
    content: "\e853";
}
.icon-gabon:before {
    content: "\e854";
}
.icon-ghana:before {
    content: "\e855";
}
.icon-guinea-bissau:before {
    content: "\e856";
}
.icon-guinea:before {
    content: "\e857";
}
.icon-ivory-coast:before {
    content: "\e858";
}
.icon-kenya:before {
    content: "\e859";
}
.icon-lesotho:before {
    content: "\e85a";
}
.icon-libya:before {
    content: "\e85b";
}
.icon-madagascar:before {
    content: "\e85c";
}
.icon-malawi:before {
    content: "\e85d";
}
.icon-mali:before {
    content: "\e85e";
}
.icon-mauritania:before {
    content: "\e85f";
}
.icon-mauritius:before {
    content: "\e860";
}
.icon-mayotte-island:before {
    content: "\e861";
}
.icon-morocco:before {
    content: "\e862";
}
.icon-mozambique:before {
    content: "\e863";
}
.icon-namibia:before {
    content: "\e864";
}
.icon-niger:before {
    content: "\e865";
}
.icon-nigeria:before {
    content: "\e866";
}
.icon-reunion-island:before {
    content: "\e867";
}
.icon-republic-of-congo:before {
    content: "\e868";
}
.icon-rwanda:before {
    content: "\e869";
}
.icon-são-tomé-and-príncipe:before {
    content: "\e86a";
}
.icon-senegal:before {
    content: "\e86b";
}
.icon-seychelles:before {
    content: "\e86c";
}
.icon-sirerra-leone:before {
    content: "\e86d";
}
.icon-somalia:before {
    content: "\e86e";
}
.icon-south-africa:before {
    content: "\e86f";
}
.icon-south-sudan:before {
    content: "\e870";
}
.icon-st-helena-and-ascension-island:before {
    content: "\e871";
}
.icon-sudan:before {
    content: "\e872";
}
.icon-tanzania:before {
    content: "\e873";
}
.icon-the-gambia:before {
    content: "\e874";
}
.icon-togo:before {
    content: "\e875";
}
.icon-tunisia:before {
    content: "\e876";
}
.icon-uganda:before {
    content: "\e877";
}
.icon-united-arab-emirates:before {
    content: "\e878";
}
.icon-western-sahara:before {
    content: "\e879";
}
.icon-zambia:before {
    content: "\e87a";
}
.icon-zimbabwe:before {
    content: "\e87b";
}
.icon-antartica:before {
    content: "\e87c";
}
.icon-afghanistan:before {
    content: "\e87d";
}
.icon-armenia:before {
    content: "\e87e";
}
.icon-azerbaijan:before {
    content: "\e87f";
}
.icon-bahrain:before {
    content: "\e880";
}
.icon-bangladesh:before {
    content: "\e881";
}
.icon-bhutan:before {
    content: "\e882";
}
.icon-bouvet-island:before {
    content: "\e883";
}
.icon-british-indian-ocean-territory:before {
    content: "\e884";
}
.icon-brunei-darussalam:before {
    content: "\e885";
}
.icon-cambodia:before {
    content: "\e886";
}
.icon-china:before {
    content: "\e887";
}
.icon-cocos-islands:before {
    content: "\e888";
}
.icon-christmas-island:before {
    content: "\e889";
}
.icon-cyprus:before {
    content: "\e88a";
}
.icon-french-southern-territories:before {
    content: "\e88b";
}
.icon-georgia:before {
    content: "\e88c";
}
.icon-heard-island-and-mcdonald-islands:before {
    content: "\e88d";
}
.icon-hong-kong:before {
    content: "\e88e";
}
.icon-india:before {
    content: "\e88f";
}
.icon-indonesia:before {
    content: "\e890";
}
.icon-iran:before {
    content: "\e891";
}
.icon-iraq:before {
    content: "\e892";
}
.icon-israel:before {
    content: "\e893";
}
.icon-japan:before {
    content: "\e894";
}
.icon-jordan:before {
    content: "\e895";
}
.icon-kazakhstan:before {
    content: "\e896";
}
.icon-kuwait:before {
    content: "\e897";
}
.icon-kyrgyzstan:before {
    content: "\e898";
}
.icon-lao-people-democratic-republic:before {
    content: "\e899";
}
.icon-lebanon:before {
    content: "\e89a";
}
.icon-macao:before {
    content: "\e89b";
}
.icon-malaysia:before {
    content: "\e89c";
}
.icon-maldives:before {
    content: "\e89d";
}
.icon-mongolia:before {
    content: "\e89e";
}
.icon-myanmar:before {
    content: "\e89f";
}
.icon-nepal:before {
    content: "\e8a0";
}
.icon-new-caledonia:before {
    content: "\e8a1";
}
.icon-north-korea:before {
    content: "\e8a2";
}
.icon-oman:before {
    content: "\e8a3";
}
.icon-pakistan:before {
    content: "\e8a4";
}
.icon-philippines:before {
    content: "\e8a5";
}
.icon-qatar:before {
    content: "\e8a6";
}
.icon-russian-federation:before {
    content: "\e8a7";
}
.icon-saudi-arabia:before {
    content: "\e8a8";
}
.icon-singapore:before {
    content: "\e8a9";
}
.icon-south-georgia:before {
    content: "\e8aa";
}
.icon-south-korea:before {
    content: "\e8ab";
}
.icon-sri-lanka:before {
    content: "\e8ac";
}
.icon-suriname:before {
    content: "\e8ad";
}
.icon-syrian-arab-republic:before {
    content: "\e8ae";
}
.icon-taiwan:before {
    content: "\e8af";
}
.icon-tajikistan:before {
    content: "\e8b0";
}
.icon-thailand:before {
    content: "\e8b1";
}
.icon-timor-leste:before {
    content: "\e8b2";
}
.icon-turkey:before {
    content: "\e8b3";
}
.icon-turkmenistan:before {
    content: "\e8b4";
}
.icon-uzbekistan:before {
    content: "\e8b5";
}
.icon-vietnam:before {
    content: "\e8b6";
}
.icon-alberta:before {
    content: "\e8b8";
}
.icon-british-columbia:before {
    content: "\e8b9";
}
.icon-manitoba:before {
    content: "\e8ba";
}
.icon-ontario:before {
    content: "\e8bb";
}
.icon-price-edward-island:before {
    content: "\e8bc";
}
.icon-yukon:before {
    content: "\e8bd";
}
.icon-nova-scotia:before {
    content: "\e8be";
}
.icon-new-brunswick:before {
    content: "\e8bf";
}
.icon-saskatchewan:before {
    content: "\e8c0";
}
.icon-nunavut:before {
    content: "\e8c1";
}
.icon-newfoundland:before {
    content: "\e8c2";
}
.icon-northwest-territories:before {
    content: "\e8c3";
}
.icon-quebec:before {
    content: "\e8c4";
}
.icon-aland-islands:before {
    content: "\e8c5";
}
.icon-albania:before {
    content: "\e8c6";
}
.icon-andora:before {
    content: "\e8c7";
}
.icon-austria:before {
    content: "\e8c8";
}
.icon-belarus:before {
    content: "\e8c9";
}
.icon-belgium:before {
    content: "\e8ca";
}
.icon-bosnia-and-herzegovina:before {
    content: "\e8cb";
}
.icon-bulgaria:before {
    content: "\e8cc";
}
.icon-croatia:before {
    content: "\e8cd";
}
.icon-czech-republic:before {
    content: "\e8ce";
}
.icon-denmark:before {
    content: "\e8cf";
}
.icon-estonia:before {
    content: "\e8d0";
}
.icon-faroe-islands:before {
    content: "\e8d1";
}
.icon-finland:before {
    content: "\e8d2";
}
.icon-france:before {
    content: "\e8d3";
}
.icon-germany:before {
    content: "\e8d4";
}
.icon-gibraltar:before {
    content: "\e8d5";
}
.icon-greece:before {
    content: "\e8d6";
}
.icon-guernsey:before {
    content: "\e8d7";
}
.icon-hungary:before {
    content: "\e8d8";
}
.icon-iceland:before {
    content: "\e8d9";
}
.icon-ireland:before {
    content: "\e8da";
}
.icon-isle-of-man:before {
    content: "\e8db";
}
.icon-italy:before {
    content: "\e8dc";
}
.icon-latvia:before {
    content: "\e8dd";
}
.icon-liechtenstein:before {
    content: "\e8de";
}
.icon-lithuania:before {
    content: "\e8df";
}
.icon-luxembourg:before {
    content: "\e8e0";
}
.icon-macedonia:before {
    content: "\e8e1";
}
.icon-malta:before {
    content: "\e8e2";
}
.icon-moldova:before {
    content: "\e8e3";
}
.icon-monaco:before {
    content: "\e8e4";
}
.icon-montenegro:before {
    content: "\e8e5";
}
.icon-netherlands:before {
    content: "\e8e6";
}
.icon-norway:before {
    content: "\e8e7";
}
.icon-poland:before {
    content: "\e8e8";
}
.icon-portugal:before {
    content: "\e8e9";
}
.icon-romania:before {
    content: "\e8ea";
}
.icon-san-marino:before {
    content: "\e8eb";
}
.icon-serbia:before {
    content: "\e8ec";
}
.icon-slovakia:before {
    content: "\e8ed";
}
.icon-slovenia:before {
    content: "\e8ee";
}
.icon-spain:before {
    content: "\e8ef";
}
.icon-svalbard-and-jan-mayen:before {
    content: "\e8f0";
}
.icon-sweden:before {
    content: "\e8f1";
}
.icon-switzerland:before {
    content: "\e8f2";
}
.icon-ukraine:before {
    content: "\e8f3";
}
.icon-united-kingdom:before {
    content: "\e8f4";
}
.icon-vatican-city-state:before {
    content: "\e8f5";
}
.icon-anguilla:before {
    content: "\e8f6";
}
.icon-bahamas:before {
    content: "\e8f7";
}
.icon-barbados:before {
    content: "\e8f8";
}
.icon-bermuda:before {
    content: "\e8f9";
}
.icon-antigua-and-barbuda:before {
    content: "\e8fa";
}
.icon-aruba:before {
    content: "\e8fb";
}
.icon-costa-rica:before {
    content: "\e8fc";
}
.icon-guatemala:before {
    content: "\e8fd";
}
.icon-dominica:before {
    content: "\e8fe";
}
.icon-canada:before {
    content: "\e8ff";
}
.icon-guadeloupe:before {
    content: "\e900";
}
.icon-jamaica:before {
    content: "\e901";
}
.icon-dominican-republic:before {
    content: "\e902";
}
.icon-martinique:before {
    content: "\e903";
}
.icon-panama:before {
    content: "\e904";
}
.icon-saint-barthélemy:before {
    content: "\e905";
}
.icon-honduras:before {
    content: "\e906";
}
.icon-cuba:before {
    content: "\e907";
}
.icon-bonaire-sint-eustatius-and-saba:before {
    content: "\e908";
}
.icon-saint-kitts-and-nevis:before {
    content: "\e909";
}
.icon-montserrat:before {
    content: "\e90a";
}
.icon-cayman-islands:before {
    content: "\e90b";
}
.icon-grenada:before {
    content: "\e90c";
}
.icon-puerto-rico:before {
    content: "\e90d";
}
.icon-haiti:before {
    content: "\e90e";
}
.icon-curaçao:before {
    content: "\e90f";
}
.icon-mexico:before {
    content: "\e910";
}
.icon-greenland:before {
    content: "\e911";
}
.icon-el-salvador:before {
    content: "\e912";
}
.icon-saint-lucia:before {
    content: "\e913";
}
.icon-saint-vincent-and-the-grenadines:before {
    content: "\e914";
}
.icon-trinidad-and-tobago:before {
    content: "\e915";
}
.icon-saint-pierre-and-miquelon:before {
    content: "\e916";
}
.icon-virgin-islands-us:before {
    content: "\e917";
}
.icon-turks-and-caicos-islands:before {
    content: "\e918";
}
.icon-virgin-islands---british:before {
    content: "\e919";
}
.icon-saint-martin:before {
    content: "\e91a";
}
.icon-sint-maarten:before {
    content: "\e91b";
}
.icon-american-samoa:before {
    content: "\e91c";
}
.icon-australia:before {
    content: "\e91d";
}
.icon-cook-islands:before {
    content: "\e91e";
}
.icon-fiji:before {
    content: "\e91f";
}
.icon-french-polynesia:before {
    content: "\e920";
}
.icon-guam:before {
    content: "\e921";
}
.icon-kiribati:before {
    content: "\e922";
}
.icon-new-zealand:before {
    content: "\e923";
}
.icon-niue:before {
    content: "\e924";
}
.icon-nauru:before {
    content: "\e925";
}
.icon-tonga:before {
    content: "\e926";
}
.icon-pitcairn:before {
    content: "\e927";
}
.icon-norfolk-island:before {
    content: "\e928";
}
.icon-vanuatu:before {
    content: "\e929";
}
.icon-solomon-islands:before {
    content: "\e92a";
}
.icon-palau:before {
    content: "\e92b";
}
.icon-samoa:before {
    content: "\e92c";
}
.icon-wallis-and-futuna:before {
    content: "\e92d";
}
.icon-papua-new-guinea:before {
    content: "\e92e";
}
.icon-tokelau:before {
    content: "\e92f";
}
.icon-argentina:before {
    content: "\e930";
}
.icon-bolivia:before {
    content: "\e931";
}
.icon-brazil:before {
    content: "\e932";
}
.icon-chile:before {
    content: "\e933";
}
.icon-columbia:before {
    content: "\e934";
}
.icon-ecuador:before {
    content: "\e935";
}
.icon-falkland-islands:before {
    content: "\e936";
}
.icon-french-guiana:before {
    content: "\e937";
}
.icon-guyana:before {
    content: "\e938";
}
.icon-nicaragua:before {
    content: "\e939";
}
.icon-paraguay:before {
    content: "\e93a";
}
.icon-peru:before {
    content: "\e93b";
}
.icon-uruguay:before {
    content: "\e93c";
}
.icon-venezuela:before {
    content: "\e93d";
}
.icon-alabama:before {
    content: "\e93e";
}
.icon-alaska:before {
    content: "\e93f";
}
.icon-arizona:before {
    content: "\e940";
}
.icon-arkansas:before {
    content: "\e941";
}
.icon-california:before {
    content: "\e942";
}
.icon-colorado:before {
    content: "\e943";
}
.icon-connecticut:before {
    content: "\e944";
}
.icon-delaware:before {
    content: "\e945";
}
.icon-florida:before {
    content: "\e946";
}
.icon-georgia-1:before {
    content: "\e947";
}
.icon-hawai:before {
    content: "\e948";
}
.icon-idaho:before {
    content: "\e949";
}
.icon-illinois:before {
    content: "\e94a";
}
.icon-indiana:before {
    content: "\e94b";
}
.icon-iowa:before {
    content: "\e94c";
}
.icon-kansas:before {
    content: "\e94d";
}
.icon-kentucky:before {
    content: "\e94e";
}
.icon-louisiana:before {
    content: "\e94f";
}
.icon-maine:before {
    content: "\e950";
}
.icon-maryland:before {
    content: "\e951";
}
.icon-massachusetts:before {
    content: "\e952";
}
.icon-michigan:before {
    content: "\e953";
}
.icon-minnesota:before {
    content: "\e954";
}
.icon-mississippi:before {
    content: "\e955";
}
.icon-missouri:before {
    content: "\e956";
}
.icon-montana:before {
    content: "\e957";
}
.icon-nebraska:before {
    content: "\e958";
}
.icon-nevada:before {
    content: "\e959";
}
.icon-new-hampshire:before {
    content: "\e95a";
}
.icon-new-jersey:before {
    content: "\e95b";
}
.icon-new-mexico:before {
    content: "\e95c";
}
.icon-new-york:before {
    content: "\e95d";
}
.icon-north-carolina:before {
    content: "\e95e";
}
.icon-north-dakota:before {
    content: "\e95f";
}
.icon-ohio:before {
    content: "\e960";
}
.icon-oklahoma:before {
    content: "\e961";
}
.icon-oregon:before {
    content: "\e962";
}
.icon-rhode-island:before {
    content: "\e963";
}
.icon-south-carolina:before {
    content: "\e964";
}
.icon-pennsylvania:before {
    content: "\e965";
}
.icon-south-dakota:before {
    content: "\e966";
}
.icon-tennessee:before {
    content: "\e967";
}
.icon-texas:before {
    content: "\e968";
}
.icon-utah:before {
    content: "\e969";
}
.icon-vermont:before {
    content: "\e96a";
}
.icon-virginia:before {
    content: "\e96b";
}
.icon-washington:before {
    content: "\e96c";
}
.icon-west-virginia:before {
    content: "\e96d";
}
.icon-wisconsin:before {
    content: "\e96e";
}
.icon-wyoming:before {
    content: "\e96f";
}
.icon-belize:before {
    content: "\e97d";
}
.icon-bali:before {
    content: "\e8b7";
}
.icon-antigua:before {
    content: "\e97e";
}
.icon-amalfi-coast:before {
    content: "\e97f";
}

.icon-continent-south-america:before {
    content: "\e973";
}
.icon-continent-africa:before {
    content: "\e974";
}
.icon-continent-asia:before {
    content: "\e975";
}
.icon-continent-canada:before {
    content: "\e976";
}
.icon-continent-caribbean:before {
    content: "\e977";
}
.icon-continent-central-america:before {
    content: "\e978";
}
.icon-continent-europe:before {
    content: "\e979";
}
.icon-continent-mexico:before {
    content: "\e97a";
}
.icon-continent-oceania:before {
    content: "\e97b";
}
.icon-continent-united-states:before {
    content: "\e97c";
}
