@import "../../styles/variables";

.background {
    background-image: linear-gradient(
        134deg,
        #eeeeee 15%,
        rgba(216, 216, 216, 0.5) 100%
    );
    background-position: right bottom;
    background-repeat: no-repeat;
    background-size: cover;
    border: none;
    height: calc(100vh - 528px);
    @media only screen and (min-width: 768px) {
        height: calc(100vh - 200px);
    }
    @media only screen and (min-width: 992px) {
        height: calc(100vh - 216px);
    }
}
.contentWrapper {
    position: relative;
    z-index: 100;
    max-width: 1200px;
    margin: 0 auto;
    padding: 15vh 0 0 0;
}
.headline {
    font-family: $heading-font;
    font-weight: $regular;
    font-size: 21px;
    color: white;
    letter-spacing: 0.75px;
    padding: 0 0 40px;
}
.subhead {
    font-family: $heading-font;
    font-weight: $bold;
    font-size: 36px;
    color: white;
    letter-spacing: 1.29px;
    padding: 0 0 50px;
    max-width: 780px;
}

@media only screen and (max-width: 767px) {
    .contentWrapper {
        margin-top: 90px;
    }
    .headline {
        font-size: 18px;
        letter-spacing: 0.64px;
        padding-bottom: 16px;
    }
    .subhead {
        font-size: 24px;
        letter-spacing: 0.86px;
        padding-bottom: 25px;
    }
}
