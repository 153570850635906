.detailsText {
    h4 {
        color: #354b54;
        font-weight: bold;
    }
    p {
        color: #354b54;
        padding-bottom: 20px;
    }
    p:last-child {
        padding-bottom: 0;
    }
    ul {
        color: #354b54;
        list-style-type: disc;
        margin-left: 18px;
        padding-bottom: 20px;
    }
    li {
        padding-bottom: 4px;
    }
    a {
        text-decoration: underline;
    }
}
